import { Employee, EmployeeExpiredResume, EmployeeSetRole } from "@/models";
import { AxiosResponse } from "axios";
import API from "./config";
import { RoleEnum } from "@/util";

export default {
  getEmployees(active?: boolean): Promise<AxiosResponse<Employee[]>> {
    return active != null
      ? API.get<Employee[]>("/employee?active=" + active)
      : API.get<Employee[]>("/employee");
  },
  getEmployee(id: string): Promise<AxiosResponse<Employee>> {
    return API.get(`/employee/` + id);
  },
  getEmployeeOrCreate(
    id: string,
    email: string,
  ): Promise<AxiosResponse<Employee>> {
    return API.get(`/employee/` + id + `?create=true&email=` + email);
  },
  createBlankEmployee(
    id: string,
    email: string,
    firstName: string | null,
    lastName: string | null,
  ): Promise<AxiosResponse<Employee>> {
    return API.get(
      `/employee/` +
        id +
        `?create=true&email=` +
        email +
        `&firstName=` +
        firstName +
        `&lastName=` +
        lastName,
    );
  },
  putEmployee(employee: Employee): Promise<AxiosResponse<Employee>> {
    employee.active = true;
    return API.put(`/employee/` + employee.id, employee);
  },
  addEmployee(employee: Employee): Promise<AxiosResponse<Employee>> {
    employee.active = true;
    return API.post(`/employee`, employee);
  },
  deleteEmployee(employeeId: string): Promise<AxiosResponse<null>> {
    return API.delete(`/employee/` + employeeId);
  },
  postEmployee(employee: Employee): Promise<AxiosResponse<Employee>> {
    employee.active = true;
    return API.post(`/employee`);
  },
  postRole(
    employeeId: string,
    role: RoleEnum,
  ): Promise<AxiosResponse<Employee>> {
    const employeeRole: EmployeeSetRole = { type: role };
    return API.post(`/employee/${employeeId}/role`, employeeRole);
  },
  activate(
    active: boolean,
    employeeId: string,
    updateResume?: boolean,
  ): Promise<AxiosResponse<Employee>> {
    return updateResume == null
      ? API.post(`/employee/` + employeeId + `?active=` + active)
      : API.post(
          `/employee/` +
            employeeId +
            `?active=` +
            active +
            "&updateResume=" +
            updateResume,
        );
  },
  getEmployeeExpiredResume(): Promise<AxiosResponse<EmployeeExpiredResume[]>> {
    return API.get<EmployeeExpiredResume[]>("/employee/expiredResumes");
  },
  getTemporaryEmployees(
    temporary: boolean,
  ): Promise<AxiosResponse<Employee[]>> {
    return API.get<Employee[]>(`/employee?active=true&temporary=${temporary}`);
  },
  toggleBilingual(
    employeeId: string,
    bilingual: boolean,
  ): Promise<AxiosResponse<Employee>> {
    return API.post(`/employee/` + employeeId + `?bilingual=` + bilingual);
  },
};
