import { AxiosResponse } from "axios";
import API from "./config";
import {
  Certification,
  Employee,
  ReconcileCertifications,
  ReconcileEmployees,
  ReconcileSkills,
  ReconcileVerticals,
  Role,
  Skill,
  Vertical,
  ReconcileLearnings,
  Learning,
} from "@/models";

export default {
  uploadEmployees(file: any): Promise<AxiosResponse<null>> {
    return API.post<null>("/employee?active=", file);
  },
  getRoles(): Promise<AxiosResponse<Role[]>> {
    return API.get<Role[]>("/admin/role");
  },
  reconcileCertifications(
    reconcileCertifications: ReconcileCertifications,
  ): Promise<AxiosResponse<Certification>> {
    return API.post(`/admin/reconcile/certifications`, reconcileCertifications);
  },
  reconcileVerticals(
    reconcileVerticals: ReconcileVerticals,
  ): Promise<AxiosResponse<Vertical>> {
    return API.post(`/admin/reconcile/verticals`, reconcileVerticals);
  },
  reconcileSkills(
    reconcileSkills: ReconcileSkills,
  ): Promise<AxiosResponse<Skill>> {
    return API.post(`/admin/reconcile/skills`, reconcileSkills);
  },
  reconcileEmployees(
    oldEmployeeId: string,
    newEmployeeId: string,
  ): Promise<AxiosResponse<Skill>> {
    const reconcileRequest: ReconcileEmployees = {} as ReconcileEmployees;
    reconcileRequest.oldEmployeeId = oldEmployeeId;
    reconcileRequest.newEmployeeId = newEmployeeId;

    return API.post(`/admin/reconcile/employees`, reconcileRequest);
  },
  resetAIClicks(clickRecordId: number): Promise<AxiosResponse<null>> {
    return API.put(`/admin/ai-clicks/${clickRecordId}/reset-clicks`);
  },
  getAIClicks(): Promise<AxiosResponse<null>> {
    return API.get("/admin/ai-clicks");
  },
  getManagedEmployees(managerId: string): Promise<AxiosResponse<Employee[]>> {
    return API.get(`/admin/manager/${managerId}/employees`);
  },
  reconcileLearnings(
    reconcileLearnings: ReconcileLearnings,
  ): Promise<AxiosResponse<Learning>> {
    return API.post(`/admin/reconcile/learnings`, reconcileLearnings);
  },
  transferLearning(learningId: number): Promise<AxiosResponse<null>> {
    return API.post(`/admin/transfer/learning/${learningId}`);
  },
  transferCertification(certificationId: number): Promise<AxiosResponse<null>> {
    return API.post(`/admin/transfer/certification/${certificationId}`);
  },
  getAdministrators(): Promise<AxiosResponse<Employee[]>> {
    return API.get(`/admin/administrator`);
  },
  deleteAdministrator(employeeId: string) {
    return API.delete(`/admin/administrator/${employeeId}`);
  },
  addAdministrator(employeeId: string) {
    return API.post(`/admin/administrator/${employeeId}`);
  },
};
