/* eslint-disable no-console */
import { reactive, ref } from "vue";
import { AdminAPI, EmployeeAPI, ResumeAPI } from "@/api";
import { UserContext } from "@/models/UserContext";
import { auth } from "@/store/auth";
import { Employee, NewResume, Resume, Role } from "@/models";
import { AxiosResponse } from "axios";
import { RoleEnum } from "@/util";

export const data = ref(null);

const defaultRole: Role = {
  id: 3,
  type: RoleEnum.EMPLOYEE,
};

export const context: UserContext = {
  id: "",
  email: "",
  role: defaultRole,
  resumeId: null,
  manager: {} as Employee,
  managedEmployees: [],
};

export async function load() {
  await EmployeeAPI.getEmployeeOrCreate(
    auth.account!.localAccountId,
    auth.account!.username,
  )
    .then(async (response) => {
      context.id = response.data.id;
      context.email = response.data.email;
      context.role = response.data.role;
      context.resumeId = response.data.resumeId;
      context.manager = response.data.manager;

      AdminAPI.getManagedEmployees(context.id).then((response) => {
        context.managedEmployees = response.data;
      });

      if (context.resumeId === undefined || context.resumeId === null) {
        const newResume: NewResume = {
          employeeId: context.id,
        };

        await ResumeAPI.postResume(newResume)
          .then((response: AxiosResponse<Resume>) => {
            context.resumeId = response.data.id;
          })
          .catch((error) => {
            throw error;
          });
      }
    })
    .catch((err) => {
      throw err;
    });
}

// export async function load() {
//   await EmployeeAPI.getEmployee(auth.account!.localAccountId)
//     .then((response) => {
//       context.id = response.data.id;
//       context.email = response.data.email;
//       context.role = response.data.role;
//       context.resumeId = response.data.resumeId;

//       if (context.resumeId === null) {
//         const newResume: NewResume = {
//           employeeId: context.id,
//         };

//         ResumeAPI.postResume(newResume)
//           .then((response: AxiosResponse<Resume>) => {
//             context.resumeId = response.data.id;
//           })
//           .catch((error) => {
//             throw error;
//           });
//       }
//     })
//     .catch((err) => {
//       throw err;
//     });
// }

export const user = reactive({
  data,
  context,
  load,
});
