import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { auth } from "@/store/auth";
import { VueNavigationClient } from "./helpers";
import { user } from "@/store/user";

// special routes
const unmatched = "/:pathMatch(.*)*";
const unguarded = ["/login"];

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Resume Listing",
    component: () => import("@/components/pages/ResumesPage.vue"),
    // props: { authenticatedUser: user },
  },
  {
    path: "/resume/:id",
    name: "Resume",
    props: true,
    component: () => import("@/components/pages/ResumePage.vue"),
  },
  {
    path: "/configuration",
    name: "Configuration",
    component: () => import("@/components/pages/ConfigurationPage.vue"),
  },
  {
    path: "/users",
    name: "User Management",
    component: () => import("@/components/pages/UserManagementPage.vue"),
    // props: { authenticatedUser: user },
  },
  {
    path: "/employee-upload",
    name: "Employee Upload",
    component: () => import("@/components/pages/EmployeeUploadPage.vue"),
    // props: { authenticatedUser: user },
  },
  {
    path: "/metrics",
    name: "Metrics",
    component: () => import("@/components/pages/MetricsPage.vue"),
    // props: { authenticatedUser: user },
  },
  {
    path: "/employees",
    name: "Employees",
    component: () => import("@/components/pages/EmployeesPage.vue"),
    // props: { authenticatedUser: user },
  },
  {
    path: "/managers",
    name: "Managers",
    component: () => import("@/components/pages/ManagersPage.vue"),
  },
  {
    path: "/skills/dashboard",
    name: "Skills Dashboard",
    component: () => import("@/components/pages/SkillsDashboardPage.vue"),
    // props: { authenticatedUser: user },
  },
  {
    path: "/skills/profile/:id",
    name: "Skills Profile",
    props: true,
    component: () => import("@/components/pages/SkillsProfilePage.vue"),
  },
  {
    path: "/search",
    name: "Advanced Search",
    component: () => import("@/components/pages/AdvancedSearchPage.vue"),
    // props: { authenticatedUser: user },
  },
  {
    path: "/reconcile-data",
    name: "Reconcile Data",
    component: () => import("@/components/pages/ReconcileDataPage.vue"),
  },
  {
    path: "/FAQ",
    name: "FAQ",
    component: () => import("@/components/pages/FAQPage.vue"),
  },
  {
    path: "/contact",
    name: "Contact Us",
    component: () => import("@/components/pages/ContactPage.vue"),
  },
  {
    path: "/resume-tracker",
    name: "Resume Tracker",
    component: () => import("@/components/pages/ResumeTrackerPage.vue"),
  },
  {
    path: "/bench-management",
    name: "Bench Management",
    component: () => import("@/components/pages/BenchManagementPage.vue"),
  },
  {
    path: "/resume-review",
    name: "Resume Update Management",
    component: () => import("@/components/pages/ChangeLogPage.vue"),
  },
  {
    path: "/logs",
    name: "Application Logs",
    component: () => import("@/components/pages/LogsPage.vue"),
  },
  {
    path: "/release-notes",
    name: "Release Notes",
    component: () => import("@/components/pages/ReleaseNotesPage.vue"),
  },
  {
    path: "/transfer-data",
    name: "Transfer Data",
    component: () => import("@/components/pages/TransferDataPage.vue"),
  },
  {
    path: "/data-request",
    name: "Data Requests",
    component: () => import("@/components/pages/DataRequestsPage.vue"),
  },
  { path: "/:pathMatch(.*)*", redirect: "/" },
];

// const router = createRouter({
//   history: createWebHistory(process.env.BASE_URL),
//   routes: [
//     route("/", "HomePage"),
//     route("/resume/:id", "ResumePage"),
//     hook("/login", auth.login),
//     route(unmatched, "HomePage"),
//   ],
// });

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// hook MSAL into router
const client = new VueNavigationClient(router);

// set up auth and guard routes
router.beforeEach(async (to, _from, next) => {
  // 404
  if (to.matched[0]?.path === unmatched) {
    return next();
  }

  // guarded
  const guarded = unguarded.every((path) => path !== to.path);
  if (guarded) {
    // initialized
    if (!auth.initialized) {
      await auth.initialize(client);
    }
  }

  // TODO Note - This is what was messing up the 'open to new tab' page load. leave commented for not and delete later
  // if (user.context.role.type == "EMPLOYEE") {
  //   if (
  //     to.name == "Resume" &&
  //     to.params["id"] !== user.context.resumeId?.toString()
  //   ) {
  //     next({ path: `/resume/${user.context.resumeId}`, replace: true });
  //   }
  // }

  // authorised
  if (auth.account) {
    if (!user.data) {
      await user.load();
    }

    return next();
  }

  // unauthorised
  try {
    await auth.login();
    return next();
  } catch (err) {
    return next(false);
  }

  // unguarded
  next();
});

router.afterEach(async (to, from) => {
  if (
    user.context.role.type !== "EMPLOYEE" &&
    to.name == "Resume" &&
    from.name == "Resume" &&
    to.params["id"] !== from.params["id"]
  ) {
    const currentHash = window.location.hash;
    router.go(0);
    window.addEventListener("load", () => {
      if (currentHash) {
        window.location.hash = currentHash;
      }
    });
  }
});

export default router;
