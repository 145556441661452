// @ts-ignore
import { createStore } from "vuex";
import { SearchCriteria, Resume } from "@/models";

interface State {
  searchCriteria: SearchCriteria;
  resumesToDownload: Resume[];
  initialized: boolean;
}

const getDefaultSearchCriteria = () => {
  return {
    jobTitle: [],
    office: [],
    yearsOfExperience: 0,
    skills: [],
    verticals: [],
    summary: "",
    lengthOfProject: 0,
    responsibilities: "",
    onlyPlato: false,
    certifications: [],
    learning: [],
    managerId: "",
    bilingual: false,
  };
};

export const store: any = new createStore<State>({
  state: {
    searchCriteria: getDefaultSearchCriteria(),
    resumesToDownload: [],
    init: true,
  },

  mutations: {
    setSearchCriteria(state: State, criteria: SearchCriteria) {
      state.searchCriteria = criteria;
      if (
        JSON.stringify(criteria) === JSON.stringify(getDefaultSearchCriteria())
      ) {
        state.searchCriteria = getDefaultSearchCriteria();
        state.initialized = false;
      } else {
        state.searchCriteria = criteria;
        state.initialized = true;
      }
    },
    resetSearchCriteria(state: State) {
      state.searchCriteria = getDefaultSearchCriteria();
      state.initialized = false;
    },
    setResumesToDownload(state: State, resumes: Resume[]) {
      state.resumesToDownload = resumes;
    },
    resetResumesToDownload(state: State) {
      state.resumesToDownload = [];
    },
  },
});
