// @ts-ignore
import { createStore } from "vuex";
import { Resume } from "@/models";

interface State {
  resumeContext: Resume;
}

export const store: any = new createStore<State>({
  state: {
    resumeContext: {} as Resume,
  },

  mutations: {
    setResumeContext(state: State, resume: Resume) {
      state.resumeContext = resume;
    },
    resetResumeContext(state: State) {
      state.resumeContext = {} as Resume;
    },
  },
});
