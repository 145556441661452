// @ts-ignore
import { createStore } from "vuex";
import { ChangeLog, Resume } from "@/models";

interface State {
  resumeContext: Resume;
  changeLogContext: ChangeLog;
}

export const store: any = new createStore<State>({
  state: {
    resumeContext: {} as Resume,
    changeLogContext: {} as ChangeLog,
  },

  mutations: {
    setResumeContext(state: State, resume: Resume) {
      state.resumeContext = resume;
    },
    resetResumeContext(state: State) {
      state.resumeContext = {} as Resume;
    },
    setChangeLogContext(state: State, changeLog: ChangeLog) {
      state.changeLogContext = changeLog;
    },
    resetChangeLogContext(state: State) {
      state.changeLogContext = {} as ChangeLog;
    },
  },
});
