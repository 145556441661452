<template>
  <Dialog
    v-model:visible="isVisible"
    class="p-0 m-0"
    modal
    :pt="{
      mask: {
        style: 'backdrop-filter: blur(1px)',
      },
    }"
  >
    <template #container="{ closeCallback }">
      <div
        class="px-3"
        style="background-color: white; border-radius: 13px; width: 40rem"
      >
        <Message :closable="false" severity="error"
          ><h5 style="vertical-align: middle">
            There was an error precessing your request
          </h5>
        </Message>
        <div class="p-4">
          <div class="pb-3">
            <label class="font-semibold pr-2"
              >there was an issue accessing:
            </label>
            <label class="font-italic"> {{ errorUrl }}</label>
          </div>
          <div class="pb-3">
            <label style="color: red" class="font-semibold">
              Error code: {{ errorStatus }}</label
            >
          </div>
          <div class="pb-3">
            <label class="font-semibold"> Options: </label>
            <ul class="pl-3">
              <li class="font-semibold">
                - Send The Resume Builder team an email to notify them of the
                issue
              </li>
              <li class="font-semibold">- Refresh the page</li>
              <li class="font-semibold">- Return to the home page</li>
            </ul>
          </div>
          <div class="pb-3" v-if="emailSent == true">
            <Message :closable="false">
              <label class="font-semibold">
                An email has been sent to the resume builder team notifying them
                of this issue</label
              >
            </Message>
          </div>
          <div class="pb-3" v-if="emailFailed == true">
            <Message severity="error" :closable="false">
              <label class="font-semibold">
                Email Failed to send, refresh the page and try again</label
              >
            </Message>
          </div>
          <div class="flex align-items-center pt-6 gap-2">
            <router-link :to="`/resume`">
              <Button
                label="Home"
                outlined
                severity="info"
                class="rounded-3 p-2"
                @click="closeCallback(), resetLists()"
              />
            </router-link>
            <Button
              label="Refresh"
              outlined
              severity="info"
              @click="refreshPage(), resetLists()"
              icon="pi pi-refresh"
              class="rounded p-2"
            />
            <Button
              label="Email"
              outlined
              severity="info"
              @click="sendEmail()"
              icon="pi pi-send"
              class="rounded p-2"
              :disabled="emailSent"
            />

            <Button
              outlined
              label="Close"
              severity="danger"
              icon="pi pi-times"
              @click="closeCallback(), resetLists()"
              class="ms-auto rounded p-2"
            />
          </div>
        </div>
      </div>
    </template>
  </Dialog>
</template>

<script lang="ts">
import { AxiosError } from "axios";
import { defineComponent } from "vue";
import API from "../../api/config";
import emailjs from "emailjs-com";
import { EmployeeAPI } from "@/api";
import { user } from "@/store/user";

interface Data {
  isVisible: boolean;
  errorStatus: number[];
  errorUrl: string[];
  name: string;
  email: string;
  message: string;
  errors: AxiosError[];
  emailSent: boolean;
  emailFailed: boolean;
}

export default defineComponent({
  name: "MoleculeDialogError",
  data: (): Data => {
    return {
      isVisible: false,
      errorStatus: [],
      errorUrl: [],
      name: "Error getting name",
      email: "Error getting email",
      message: "",
      errors: [],
      emailSent: false,
      emailFailed: false,
    };
  },
  watch: {
    isVisible: {
      handler(isVisible) {
        this.isVisible = isVisible;
      },
      immediate: true,
    },
    errorStatus: {
      handler(errorStatus) {
        this.errorStatus = errorStatus;
      },
      immediate: true,
    },
    errorUrl: {
      handler(errorUrl) {
        this.errorUrl = errorUrl;
      },
      immediate: true,
    },
  },
  beforeMount() {
    //check if the status code is of an error code, then show error dialog
    //You can also add 'API,interceptors.request.use() to catch requests'
    API.interceptors.response.use(
      (response) => {
        // Any status code that lie within the range of 2xx cause this function to trigger
        return response;
      },
      (error) => {
        if (error.response.status == 404) {
          this.$router.push("/");
        } else {
          // Any status codes that falls outside the range of 2xx cause this function to trigger
          this.isVisible = true;
          this.errorStatus.push(error.request.status);
          this.errorUrl.push(error.request.responseURL);
          return Promise.reject(error);
        }
      },
    );
    this.getEmployee();
  },
  methods: {
    refreshPage() {
      this.$router.go(0);
    },
    sendEmail() {
      try {
        this.message =
          "Error Handler Automated Message: \n\n " +
          this.name +
          " experienced an error while trying to access " +
          this.errorUrl +
          ", error code: " +
          this.errorStatus +
          "\n\nUsers email: " +
          this.email;
        emailjs.send(
          import.meta.env.VITE_APP_EMAILJS_SERVICE,
          import.meta.env.VITE_APP_EMAILJS_CONTACT_TEMPLATE,
          {
            from_name: "Error Handler",
            to_name: "ResumeBuilder Team",
            message: this.message,
          },
          import.meta.env.VITE_APP_EMAILJS_APP,
        );
      } catch (error) {
        this.emailFailed = true;
        console.log({ error });
      }
      //   Reset form field
      this.emailSent = true;
      this.message = "";
    },
    getEmployee() {
      EmployeeAPI.getEmployee(user.context.id)
        .then((response) => {
          this.name = response.data.firstName + " " + response.data.lastName;
          this.email = response.data.email;
        })
        .catch((error: AxiosError) => this.errors.push(error));
    },
    resetLists() {
      this.errorStatus = [];
      this.errorUrl = [];
      this.emailSent = false;
      this.emailFailed = false;
    },
  },
  components: {},
});
</script>
