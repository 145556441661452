// @ts-ignore
import { createStore } from "vuex";

interface State {
  transferFlag: Boolean;
}

export const store: any = new createStore<State>({
  state: {
    transferFlag: false,
  },

  mutations: {
    setTransferFlag(state: State) {
      state.transferFlag = true;
    },
    resetTransferFlag(state: State) {
      state.transferFlag = false;
    },
  },
});
