import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { auth as Auth } from "@/store/auth";
import {
  AdvancedSearchStore,
  ResumeStore,
  TransferStore,
  ChangeLogStore,
} from "@/store";

import mitt from "mitt";

import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import "./style.css";

import PrimeVue from "primevue/config";

import ToastService from "primevue/toastservice";
import ConfirmationService from "primevue/confirmationservice";

import "primeflex/primeflex.css";
import "primevue/resources/themes/aura-light-blue/theme.css";
// import "primevue/resources/themes/lara-light-blue/theme.css";
import "primeicons/primeicons.css";

import Dialog from "primevue/dialog";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import MultiSelect from "primevue/multiselect";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import ColumnGroup from "primevue/columngroup";
import Row from "primevue/row";
import Calendar from "primevue/calendar";
import Checkbox from "primevue/checkbox";
import InputSwitch from "primevue/inputswitch";
import Card from "primevue/card";
import SpeedDial from "primevue/speeddial";
import Textarea from "primevue/textarea";
import Panel from "primevue/panel";
import Dropdown from "primevue/dropdown";
import Slider from "primevue/slider";
import ScrollPanel from "primevue/scrollpanel";
import InputNumber from "primevue/inputnumber";
import Listbox from "primevue/listbox";
import SelectButton from "primevue/selectbutton";
import Menu from "primevue/menu";
import TreeTable from "primevue/treetable";
import Chart from "primevue/chart";
import FileUpload from "primevue/fileupload";
import TriStateCheckbox from "primevue/tristatecheckbox";
import RadioButton from "primevue/radiobutton";
import ConfirmPopup from "primevue/confirmpopup";
import ProgressSpinner from "primevue/progressspinner";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import Tooltip from "primevue/tooltip";
import ProgressBar from "primevue/progressbar";
import Message from "primevue/message";
import InlineMessage from "primevue/inlinemessage";
import SplitButton from "primevue/splitbutton";
import PickList from "primevue/picklist";
import DataView from "primevue/dataview";

const eventBus: any = mitt();

const app = createApp(App);

app.provide("eventBus", eventBus);

app.component("Dialog", Dialog);
app.component("Button", Button);
app.component("InputText", InputText);
app.component("MultiSelect", MultiSelect);
app.component("DataTable", DataTable);
app.component("Column", Column);
app.component("ColumnGroup", ColumnGroup);
app.component("Row", Row);
app.component("Calendar", Calendar);
app.component("Checkbox", Checkbox);
app.component("InputSwitch", InputSwitch);
app.component("Card", Card);
app.component("SpeedDial", SpeedDial);
app.component("Textarea", Textarea);
app.component("Panel", Panel);
app.component("Dropdown", Dropdown);
app.component("Slider", Slider);
app.component("ScrollPanel", ScrollPanel);
app.component("InputNumber", InputNumber);
app.component("Listbox", Listbox);
app.component("SelectButton", SelectButton);
app.component("Menu", Menu);
app.component("TreeTable", TreeTable);
app.component("Chart", Chart);
app.component("FileUpload", FileUpload);
app.component("TriStateCheckbox", TriStateCheckbox);
app.component("RadioButton", RadioButton);
app.component("ConfirmPopup", ConfirmPopup);
app.component("ProgressSpinner", ProgressSpinner);
app.component("Accordion", Accordion);
app.component("AccordionTab", AccordionTab);
app.component("ProgressBar", ProgressBar);
app.component("Message", Message);
app.component("InlineMessage", InlineMessage);
app.component("SplitButton", SplitButton);
app.component("PickList", PickList);
app.component("DataView", DataView);

app.directive("tooltip", Tooltip);
app.use(PrimeVue, {
  pt: {
    global: {
      css: `
              .p-datepicker-calendar-container > table > thead > tr > th {
                background: #BEB7B7;
              }

              @keyframes ripple {
                  100% {
                      opacity: 0;
                      transform: scale(2.5);
                  }
              }
          `,
    },
  },
});
app.use(ToastService);
app.use(ConfirmationService);
app.use(router);

app.provide("advancedSearchStore", AdvancedSearchStore);
app.provide("resumeStore", ResumeStore);
app.provide("transferStore", TransferStore);
app.provide("changeLogStore", ChangeLogStore);

router.isReady().then(() => {
  // Waiting for the router to be ready prevents race conditions when returning from a loginRedirect or acquireTokenRedirect
  app.mount("#app");
});

// @ts-ignore
window.Auth = Auth;
