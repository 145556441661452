import axios, { AxiosInstance } from "axios";
import { root } from "@/config/api";

const Config: AxiosInstance = axios.create({
  baseURL: root,
  // baseURL: import.meta.env.VITE_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
    Authorization: import.meta.env.VITE_APP_API_KEY,
    "Clear-Site-Data": "*",
  },
  timeout: 60000,
});

export default Config;
