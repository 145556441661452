<template>
  <Toast />
  <ScrollTop />
  <router-view />
  <MoleculeDialogError />
  <div class="text-xs font-italic">
    <router-link to="/release-notes">{{ getVersion }}</router-link>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Toast from "primevue/toast";
import ScrollTop from "primevue/scrolltop";
import { user } from "./store/user";
import { EmployeeAPI } from "./api";
import { auth } from "./store/auth";
import MoleculeDialogError from "./components/molecules/MoleculeDialogError.vue";
import { AxiosError } from "axios";
import { version } from "../package.json";

interface Data {
  errors: AxiosError[];
}

export default defineComponent({
  name: "App",
  data: (): Data => {
    return {
      errors: [],
    };
  },
  mounted() {
    EmployeeAPI.EmployeeAPI.getEmployeeOrCreate(
      auth.account!.localAccountId,
      auth.account!.username,
    )
      .then(() => {
        user.load();
      })
      .catch((error: AxiosError) => this.errors.push(error));
  },
  beforeRouteEnter(_to, _from, next) {
    !user.data ? user.load().then(next) : next();
  },
  computed: {
    user: () => user,
    auth: () => auth,
    getVersion() {
      return version;
    },
  },
  components: { Toast, ScrollTop, MoleculeDialogError },
});

// Add a response interceptor
</script>

<style></style>
