import {
  Resume,
  Experience,
  Skill,
  Summary,
  NewResume,
  Vertical,
  AIClicks,
} from "@/models";
import { AxiosResponse } from "axios";
import API from "./config";

export default {
  getResumes(
    condensed: boolean,
    active?: boolean,
  ): Promise<AxiosResponse<Resume[]>> {
    return active == null
      ? API.get<Resume[]>(`/resume?condensed=${condensed}`)
      : API.get<Resume[]>(`/resume?active=${active}&condensed=${condensed}`);
  },
  getResume(resumeId: number): Promise<AxiosResponse<Resume>> {
    return API.get(`/resume/${resumeId}`);
  },
  getExperiences(
    isPlatoExperience: boolean,
    resumeId: number,
  ): Promise<AxiosResponse<Experience[]>> {
    return API.get<Experience[]>(
      `/resume/${resumeId}/experience?isPlatoExperience=${isPlatoExperience}`,
    );
  },
  getSkills(resumeId: number): Promise<AxiosResponse<Skill[]>> {
    return API.get<Skill[]>(`/resume/${resumeId}/skill`);
  },
  postResume(resume: NewResume): Promise<AxiosResponse<Resume>> {
    return API.post(`/resume`, resume);
  },
  putResume(resume: Resume, resumeId: number): Promise<AxiosResponse<Resume>> {
    resume.active = true;
    return API.put(`/resume/${resumeId}`, resume);
  },
  activate(resume: Resume): Promise<AxiosResponse<Resume>> {
    return API.put(`/resume/${resume.id}/activate?active=${resume.active}`);
  },
  deleteResume(resumeId: number): Promise<AxiosResponse<null>> {
    return API.delete(`/resume/${resumeId}`);
  },
  deleteAllSkills(resumeId: number): Promise<AxiosResponse<null>> {
    return API.delete(`/resume/${resumeId}/skill`);
  },
  addSkills(resumeId: number, skills: Skill[]): Promise<AxiosResponse<null>> {
    return API.post(`/resume/${resumeId}/skill`, skills);
  },
  deleteSkills(
    resumeId: number,
    skills: Skill[],
  ): Promise<AxiosResponse<null>> {
    return API.put(`/resume/${resumeId}/skill`, skills);
  },
  deleteAllVerticals(resumeId: number): Promise<AxiosResponse<null>> {
    return API.delete(`/resume/${resumeId}/vertical`);
  },
  addVerticals(
    resumeId: number,
    verticals: Vertical[],
  ): Promise<AxiosResponse<null>> {
    return API.post(`/resume/` + resumeId + `/vertical`, verticals);
  },
  downloadResume(resume: Resume): Promise<AxiosResponse<Blob>> {
    return API.get(`/resume/${resume.id}/download`, {
      responseType: "blob",
    });
  },
  bulkDownload(resumes: number[]): Promise<AxiosResponse<Blob>> {
    return API.post(`/resume/bulk-download`, resumes, {
      responseType: "blob",
    });
  },
  downloadZip(fileName: String): Promise<AxiosResponse<null>> {
    return API.get(`/resume/download-zip?file=${fileName}`);
  },
  updateSummary(
    resumeId: number,
    summary: Summary,
  ): Promise<AxiosResponse<Resume>> {
    return API.put(`/resume/${resumeId}/summary`, summary);
  },
  addCertification(
    resumeId: Number,
    certificationId: Number,
  ): Promise<AxiosResponse<Resume>> {
    return API.post(`/resume/${resumeId}/certification/${certificationId}`);
  },
  deleteCertification(
    resumeId: number,
    certificationId: number,
  ): Promise<AxiosResponse<null>> {
    return API.delete(`/resume/${resumeId}/certification/${certificationId}`);
  },
  deleteVertical(
    resumeId: number,
    verticalId: number,
  ): Promise<AxiosResponse<null>> {
    return API.delete(`/resume/${resumeId}/vertical/${verticalId}`);
  },
  deleteSkill(resumeId: number, skillId: number): Promise<AxiosResponse<null>> {
    return API.delete(`/resume/${resumeId}/skill/${skillId}`);
  },
  repointResume(
    resumeId: number,
    employeeId: string,
  ): Promise<AxiosResponse<Resume>> {
    return API.put(`/resume/` + resumeId + `/repoint/` + employeeId);
  },
  getResumesByManager(managerId: string): Promise<AxiosResponse<Resume[]>> {
    return API.get<Resume[]>("/resume?managerId=" + managerId);
  },
  toggleReviewed(
    resumeId: number,
    done: boolean,
  ): Promise<AxiosResponse<Resume>> {
    return API.put(`/resume/` + resumeId + `/review?done=` + done);
  },
  getTempEmployeeResumes(
    condensed: boolean,
    active?: boolean,
  ): Promise<AxiosResponse<Resume[]>> {
    return active == null
      ? API.get<Resume[]>(
          `/resume?temporaryEmployee=true&condensed=${condensed}`,
        )
      : API.get<Resume[]>(
          `/resume?temporaryEmployee=true&active=${active}&condensed=${condensed}`,
        );
  },
  incrementAIClicks(resumeId: number): Promise<AxiosResponse<null>> {
    return API.put(`/resume/${resumeId}/ai-click`);
  },
  getAIClicks(resumeId: number): Promise<AxiosResponse<AIClicks>> {
    return API.get(`/resume/${resumeId}/ai-click`);
  },
  addLearning(
    resumeId: Number,
    learningId: Number,
  ): Promise<AxiosResponse<Resume>> {
    return API.post(`/resume/${resumeId}/learning/${learningId}`);
  },
  deleteLearning(
    resumeId: number,
    learningId: number,
  ): Promise<AxiosResponse<null>> {
    return API.delete(`/resume/${resumeId}/learning/${learningId}`);
  },
  toggleLearningIncludeOnResume(
    resumeId: number,
    learningId: number,
  ): Promise<AxiosResponse<null>> {
    return API.put(`/resume/${resumeId}/learning/${learningId}`);
  },
};
